import React from "react" 

// Section Component Imports
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/Footer";

import SEO from "../../components/seo";

import PostOutline from "./../../components/blog/PostOutline";
import {HeadingTwo} from "./../../components/Headings";
import PaddedImage from "./../../components/PaddedImage";

// Image imports
import BuildingTurkboxImage from "./../../images/blog/building-turkbox/building-turkbox.png";
import authorImage from "./../../images/blog/authors/tanayv.jpeg"
import gniResultsImage from "./../../images/blog/building-turkbox/1_8g5BQiV6rDe-w8rjH8OkYw.png"
import turkboxWidgetImage from "./../../images/blog/building-turkbox/1_dZfxtEmLDGvNC5L74xHJRg.png"
import cozadImage from "./../../images/blog/building-turkbox/1_edKmpQNcaMhKxMw5if8Shg.jpeg";


const IndexPage = () => (
  <>
    <SEO title="Building Turkbox: A way to pay for news by training AI | Turkbox Blog" description={`Some notes from our journey over the past couple of months and a roadmap for what lies ahead`} 
    meta={[
        {
          name:`og:image`,
          content: `https://www.turkbox.io${BuildingTurkboxImage}`
        },
        {
          name:`twitter:image:src`,
          content: `https://www.turkbox.io${BuildingTurkboxImage}`
        }
      ]}/>
    <Navigation/>
    <PostOutline title={`Building Turkbox: A way to pay for news by training AI`} headerImage={BuildingTurkboxImage} headerImageAlt={`Interacting with a KL reader during our research trip to Khabar Lahariya's regional office in Banda`} authorImage={authorImage} articleMetadata={
      {authorName: `Tanay Vardhan`, date: `May 9, 2019`, articleLength: `4 min`}
    }>
      <p>
      We started working on Turkbox at the end of Summer 2018, with a goal to make news and information on the internet free. As users of the internet, we‘re fundamentally frustrated with today’s online news media landscape. Online news publications are increasingly adopting the paywall business model, where readers get a certain number of free articles after which they have to buy a subscription to continue reading. This is a problem for readers and the internet in general, as information is no longer free.
      </p>

      <p>
        Well-researched news being locked away behind paywalls also aids the spread of misinformation, as less credible news becomes relatively more accessible. Therefore, we’re on a mission to restore the internet as a place for the free exchange of ideas and information.
      </p>

      <HeadingTwo>What is Turkbox</HeadingTwo>

      <p>Turkbox is a content monetization platform that allows publications to embed CAPTCHA-like data classification or annotation tasks, which readers complete to get access to articles.</p>

      <p>This gives readers a more “accessible” pay-as-you-go option, since even though articles remain behind a barrier of sorts, readers are more inclined to complete a straightforward data classification task than they are to buy a subscription to a news publication website they may or may not use in the future.</p>

      <p>But if readers aren’t paying for articles, who is? These CAPTCHA-like tasks served on Turkbox widgets are provided by companies or research groups that need manual human input to help train their machine learning models. With applications of AI and machine learning expanding across industries, datasets are getting larger, thereby opening up opportunities to crowdsource this data classification process.</p>

      <p>We hypothesize that the revenue generated using this model will equal or outperform the revenue generated by the paywall model, chiefly due to the elimination of the need to spend money to access content. Turkbox can also be used in conjunction with conventional advertisements — improved conversion increases the number of readers each advertisement is exposed to.</p>

      <PaddedImage src={gniResultsImage} alt={"Results from the 2019 GNI APAC Innovation Challenge"} float="left" width={`52%`}>Turkbox is among the recipients of the GNI Asia Pacific Innovation Funding</PaddedImage>

      <p>Turkbox is reliant on readers’ ability to complete these data classification tasks. Even with estimation, we still deal with a lot of uncertainties, which drive key parameters of our business model, including how much we can charge per classification, and the accuracy with which we can deliver labelled data.</p>

      <HeadingTwo>What’s next?</HeadingTwo>

      <p>In order to validate our main hypothesis and get concrete answers to solve some of the uncertainties we face, we plan to run a pilot of our platform with news publications.</p>

      <p>To help with this, we applied for the <a href="https://www.blog.google/around-the-globe/google-asia/here-are-winners-gni-innovation-challenge-asia-pacific/" target="_blank" rel="noopener noreferrer">Google News Initiative Asia-Pacific Innovation Challenge</a>, and received funding to run our pilot over the summer. </p>

      <p>We’ve been developing our platform, content delivery network, and the widgets which will be deployed on news publication websites. Our goal is to build widgets that integrate seamlessly with CMS platforms that news publications use, while minimizing their impact on loading time.</p>

      <p>We’ve begun testing our widgets in scenarios other than a news-paywall setting. We’ve started using Turkbox widgets for fundraising, where donors would complete tasks to contribute to the campaign. Even though we aren’t able to incentivize users the same way our eventual paywall-replacement widgets would, these deployments help us test our content delivery system and widget spawning scripts.</p>

      <PaddedImage src={turkboxWidgetImage} alt={"Widget supporting the classification of real data supplied by one of our data providers"} width={`100%`}>Widget supporting the classification of real data supplied by one of our data providers</PaddedImage><p className="clearFix"/>

      <p>We’ve also been very fortunate with the resources and support we’ve been getting from the entrepreneurial ecosystem at the University of Illinois. We participated in the NSF I-Corps program, which helped us refine our idea, and map out our product ecosystem. We also participated in the <a href="https://tec.illinois.edu/news/articles-from-tec/30694" target="_blank" rel="noopener noreferrer">Cozad New Venture competition</a>, which was a great experience for us. We placed 4th overall, and also bagged the Huawei Innovation Award for the best software solution, along with the Meyer Capel Prize. Turkbox has also been accepted into the iVenture Accelerator for its Summer 2019 cohort, and we’re looking forward to working with iVenture mentors over the summer to continue refining our product.</p>

      <PaddedImage src={cozadImage} alt={"At Cozad, we won the 4th place winner prize, the Huawei Innovation Award, and the Meyer Capel prize"} width={`100%`}>At Cozad, we won the 4th place winner prize, the Huawei Innovation Award, and the Meyer Capel prize</PaddedImage><p className="clearFix"/>

      <p>With support from the Google News Initiative, we plan to run a pilot of our platform over the summer. For the pilot, we’re going to be based out of New Delhi, with the aim of working with medium to small-sized publications across India.</p>
      
      <HeadingTwo>Work with us</HeadingTwo>

      <p>We’re looking to work with content creators of all shapes and sizes, from blogs to newspapers to magazines. If you’re interested in learning more about Turkbox, or how you can use it to monetize content, reach out over email: <a href="mailto: team@turkbox.co">team@turkbox.co</a>.</p>

      <p>Simultaneously, we’re also very interested in expanding our network of data providers. Turkbox widgets currently support image annotation and labelling tasks, but we’re always working to add more types of tasks that our widgets can support. Get in touch to discuss how our platform can help crowdsource your data classification process.</p>

      <HeadingTwo>Internships</HeadingTwo>

      <p>We also have a couple of internship opportunities over the summer:</p>

      <ul>
          <li>Software Engineering: <a target="_blank" rel="noopener noreferrer" href="https://angel.co/turkbox/jobs/539016-software-engineering-intern-summer-2019">https://angel.co/turkbox/jobs/539016-software-engineering-intern-summer-2019</a></li>
          <li>Design: <a target="_blank" rel="noopener noreferrer" href="https://angel.co/turkbox/jobs/539022-design-intern-summer-2019">https://angel.co/turkbox/jobs/539022-design-intern-summer-2019</a></li>
          <li>Sales, Growth & Marketing: <a target="_blank" rel="noopener noreferrer" href="https://angel.co/turkbox/jobs/539029-growth-sales-marketing-intern-summer-2019">https://angel.co/turkbox/jobs/539029-growth-sales-marketing-intern-summer-2019</a></li>
      </ul>

      <hr/>

      <p>I’m happy to answer any questions about Turkbox. Feel free to reach out to me on <a href="https://twitter.com/tanayvar">Twitter</a>, or at <a href="mailto: tanay@turkbox.co">tanay@turkbox.co</a>.</p>


    </PostOutline>
    <Footer/>
  </>
  
)

export default IndexPage
